export class ChatError extends Error {
    data: Record<string, any>;
    static defaultName = "ChatError";

    constructor(
        message: string,
        data: Record<string, any> = {},
        name?: string,
    ) {
        super(message);
        this.name = name || ChatError.defaultName;
        this.data = data;

        // This line is needed to maintain proper stack traces in TypeScript
        Object.setPrototypeOf(this, ChatError.prototype);
    }
}
