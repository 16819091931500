import { createFetch, useFetch } from "@vueuse/core";
import { useFirebase } from "./firebase";
const { isAuthenticated, user, getAppCheckToken, getUserIdToken } =
    useFirebase();
const config = useRuntimeConfig();
const apiBaseUrl = config.public?.apiBaseUrl || "";
const chatApiBaseUrl = config.public?.chatApiBaseUrl || "";

const options = {
    async beforeFetch({ cancel, options }: any) {
        const appCheckToken = await getAppCheckToken();
        // const idToken = await getUserIdToken(user.value);
        if (!appCheckToken) cancel();
        // console.log("idToken: ", idToken);
        options.headers = {
            ...options.headers,
            "X-Firebase-AppCheck": appCheckToken,
            "Content-Type": "application/json",
        };

        return {
            options,
        };
    },
    afterFetch(response: any) {
        return response?.data || null;
    },
    updateDataOnError: false,
    onFetchError(ctx: any) {
        return ctx.data;
    },
    immediate: false,
};

const useDefaultFetch = createFetch({
    baseUrl: apiBaseUrl,
    options,
});
const useChatApiFetch = createFetch({
    baseUrl: chatApiBaseUrl,
    options,
});

const { execute: helloWorld, data: helloWorldResponse } = useDefaultFetch("/");

export function useApiFetch() {
    return {
        helloWorld,
        helloWorldResponse,
        useDefaultFetch,
        useChatApiFetch,
    };
}
